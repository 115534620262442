import React from "react";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";

export default function MobileFooter() {
  const handleScrollTop = () => {
    document.getElementById("top-view").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="sc-psCJM hDLxEx">
      <div className="sc-pjstK jsIEAM">
        <IconButton onClick={handleScrollTop}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.93945 6.84884L7.15157 1.63672L12.3637 6.84884"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.93945 12.0608L7.15157 6.84863L12.3637 12.0608"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
      </div>
      <div className="sc-pbIaG ebFstP">
        <a href="/" role="button" className="sc-AxhUy sc-qYSYK gZxlnw">
          <SearchIcon />
          Giới thiệu
        </a>
        <a href="/" role="button" className="sc-AxhUy sc-qYSYK gZxlnw">
          <PersonOutlineIcon />
          Hỏi đáp
        </a>
        <a href="/" role="button" className="sc-AxhUy sc-qYSYK gZxlnw">
          <LogoutIcon />
          Hỗ trợ
        </a>
      </div>
    </div>
  );
}
