import React, { useState } from "react";
import { Button, Grid, IconButton, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";

import logo from "../../../assets/img/Home/Logo.png";

export default function FindOutPopup({ isOpenModal, handleOpen, content }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleShowRegisterPopup = () => {
    navigate("/auth/registration");
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return createPortal(
    <Dialog
      className="sc-fzozJi eeDXqS"
      open={isOpenModal}
      onClose={() => handleOpen()}
      fullScreen={fullScreen}
    >
      <div className="sc-fzqyOu iYPalA">
        <div className="sc-fzqKxP ifYmcU">
          <IconButton
            className="sc-pZBmh cPvhOL"
            size="small"
            onClick={() => handleOpen()}
          >
            <svg
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="17.7927"
                y="1.5293"
                width="25"
                height="25"
                rx="12.5"
                transform="rotate(45 17.7927 1.5293)"
                fill="#212225"
              />
              <path
                d="M18.0746 21.0407L21.4223 24.3884L22.8144 22.9963L19.4667 19.6486L22.9304 16.1849L21.53 14.7845L18.0663 18.2482L14.7269 14.9088L13.3348 16.3009L16.6742 19.6403L13.0365 23.2781L14.4369 24.6785L18.0746 21.0407Z"
                fill="white"
              />
            </svg>
          </IconButton>
          {content}
        </div>
        <div className="sc-pRFjI iisorR">
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <img src={logo} alt="" style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                className="sc-AxhCb sc-pANHa kzCHtF root"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                disableElevation
                onClick={handleShowRegisterPopup}
                style={{ marginLeft: "0.25rem" }}
              >
                Mở thẻ ngay
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>,
    document.getElementById("root")
  );
}
