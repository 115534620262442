import React, { useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useQuery } from "@tanstack/react-query";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { optionBank2 } from "_common/constants/bank_2";
import { paymentStatusText } from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import { formatCardNumber, formatDate } from "_common/utils/helper";
import { Button } from "antd";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import cardImg from "../../assets/img/CardWallet/Card_Holder.png";
import CardVisa from "./CardVisa";
import WithdrawPopup from "./Popup/WithdrawPopup";
import FrontCard from "./FrontCard";
import BackCard from "./BackCard";

const CardWallet = observer(() => {
  const {
    authStore: { user },
  } = useStores();
  const navigate = useNavigate();
  const [isShowDisbursementDetail, setIsShowDisbursementDetail] =
    useState(false);
  const [isOpenWithdrawPopup, setIsOpenWithdrawPopup] = useState(false);
  const [isFrontCard, setIsFrontCard] = useState(true);

  const { data, refetch } = useQuery(
    ["payment", "list_withdraw", {}],
    () =>
      PaymentApi.getList({
        params: {
          type: "withdraw",
          is_self: 0,
          page: 1,
          limit: 1,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const handleShowDisbursementDetail = () => {
    refetch();
    setIsShowDisbursementDetail(true);
  };

  return (
    <div className="card-wallet-page" style={{ paddingBottom: 80 }}>
      <WalletHeader title="Ví thẻ" showUser />
      <div className="header-page-overlay" />
      <div className="card-wallet-container">
        <div
          className="card-container"
          style={{
            marginTop: 40,
          }}
          onClick={() => setIsFrontCard(!isFrontCard)}
        >
          <span className="btn-next">
            {isFrontCard ? "Mặt sau" : "Mặt trước"}
          </span>
          {isFrontCard ? <FrontCard /> : <BackCard />}
        </div>
        <div className="card-info-container">
          <CardVisa />
          <details className="bank-link-container">
            <summary
              className="credit-card"
              onClick={() => navigate("/wallet/card/bank")}
            >
              <p style={{ fontSize: "1.25rem" }}>Liên kết ngân hàng</p>
              <p
                style={{
                  fontSize: "1.7rem",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                <img
                  src={
                    optionBank2.filter(
                      (bank) => bank.value === user?.bank_name
                    )[0]?.image
                  }
                  alt=""
                />
              </p>
              <p>
                {user?.bank_user_name?.toUpperCase()}/
                {user?.bank_number_display}
              </p>
            </summary>
          </details>
          <div className="bank-link-container">
            <div className="bank-link">
              <div className="available-balance">
                Số dư khả dụng
                <span>{formatNumber(user?.balance)} VND</span>
              </div>
              <Button
                className="btn-withdraw"
                onClick={() => setIsOpenWithdrawPopup(true)}
              >
                Rút tiền về tài khoản liên kết
                <FileDownloadOutlinedIcon />
              </Button>
              {isShowDisbursementDetail ? (
                <div className="disbursement-detail">
                  <span>Chi tiết giản ngân</span>
                  <table>
                    <tbody>
                      <tr>
                        <td>Thời gian rút tiền</td>
                        <td className="text-red">
                          {formatDate(
                            data?.data[0]?.created_at,
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Thực rút về tài khoản</td>
                        <td className="text-red">
                          {formatCurrency(data?.data[0]?.amount, "VND")}
                        </td>
                      </tr>
                      <tr>
                        <td>Trạng thái rút tiền</td>
                        <td className="text-red">
                          {paymentStatusText[data?.data[0]?.status]}
                        </td>
                      </tr>
                      <tr>
                        <td>Ghi chú</td>
                        <td className="text-red">{data?.data[0]?.note}</td>
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    onClick={() => setIsShowDisbursementDetail(false)}
                    style={{
                      color: "#007bff",
                      alignSelf: "center",
                      border: 0,
                      boxShadow: "unset",
                    }}
                  >
                    <i>
                      <u>Ẩn bớt</u>
                    </i>
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    onClick={handleShowDisbursementDetail}
                    style={{
                      color: "#007bff",
                      alignSelf: "flex-end",
                      border: 0,
                      boxShadow: "unset",
                    }}
                  >
                    <i>
                      <u>Chi tiết giản ngân</u>
                    </i>
                  </Button>
                  <span
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Thông báo:
                  </span>
                  <span style={{ color: "#ff4d4f" }}>
                    {data?.data[0]?.note}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <WithdrawPopup
        isModalOpen={isOpenWithdrawPopup}
        handleCancel={() => setIsOpenWithdrawPopup(false)}
        handleRefetchDisbursementDetail={refetch}
      />
    </div>
  );
});

export default CardWallet;
