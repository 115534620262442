import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import logo from "../../../../assets/img/Home/Logo.png";

function HeaderTop() {
  const navigate = useNavigate();
  const handleShowRegisterPopup = () => {
    navigate("/auth/login");
  };

  return (
    <div className="sc-pJurq cTjcEC">
      <div className="sc-oTBUA hXGkGv">
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <img src={logo} alt="" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Button
              className="sc-AxhCb sc-qQxXP dYXPJL root"
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              disableElevation
              onClick={handleShowRegisterPopup}
            >
              Đăng nhập
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default HeaderTop;
