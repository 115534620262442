import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import {
  formatCardNumber,
  formatDate,
  handleCopyLink,
} from "_common/utils/helper";
import { Button, Col, Row } from "antd";
import { get } from "lodash";
import { formatNumber } from "_common/utils/formatValue";

const CardVisa = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <details className="credit-card-container">
      <summary className="credit-card">
        <p style={{ fontSize: "1.25rem" }}>Thẻ tín dụng</p>
        <p style={{ fontSize: "1.7rem", fontWeight: "bold" }}>100,000,000 đ</p>
        <p>
          Online plus Credit 2 in 1/
          {get(user, "cards[0].card_number_display", "")}
        </p>
      </summary>
      <div className="credit-card-info" style={{ marginTop: "1rem" }}>
        <Row>
          <Col span={24} style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            Số thẻ
          </Col>
          <Col span={24}>
            <Row>
              <Col
                span={20}
                style={{
                  fontSize: "1.2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {formatCardNumber(get(user, "cards[0].card_number"))}
              </Col>
              <Col span={4}>
                <Button
                  style={{ color: "white", backgroundColor: "#0060a0" }}
                  onClick={() =>
                    handleCopyLink(get(user, "cards[0].card_number"))
                  }
                >
                  Copy
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            MM/YY
          </Col>
          <Col span={24}>
            <Row>
              <Col
                span={20}
                style={{
                  fontSize: "1.2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {get(user, "cards[0].date_expire")}
              </Col>
              <Col span={4}>
                <Button
                  style={{ color: "white", backgroundColor: "#0060a0" }}
                  onClick={() =>
                    handleCopyLink(get(user, "cards[0].date_expire"))
                  }
                >
                  Copy
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            CVV
          </Col>
          <Col span={24}>
            <Row>
              <Col
                span={20}
                style={{
                  fontSize: "1.2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {get(user, "cards[0].cvg")}
              </Col>
              <Col span={4}>
                <Button
                  style={{ color: "white", backgroundColor: "#0060a0" }}
                  onClick={() => handleCopyLink(get(user, "cards[0].cvg"))}
                >
                  Copy
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            Họ và Tên
          </Col>
          <Col span={24}>
            <Row>
              <Col
                span={20}
                style={{
                  fontSize: "1.2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {get(user, "cards[0].card_owner", "")?.toUpperCase()}
              </Col>
              <Col span={4}>
                <Button
                  style={{ color: "white", backgroundColor: "#0060a0" }}
                  onClick={() =>
                    handleCopyLink(get(user, "cards[0].card_owner"))
                  }
                >
                  Copy
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="credit-card-history" style={{ marginTop: "1rem" }}>
        <div style={{ fontSize: "1rem" }}>Lịch sử giao dịch</div>
        {get(user, "histories", [])?.map((item) => (
          <div className="history-item" key={item?.id}>
            <Row>
              <Col span={12}>{item?.description}</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                {item?.entity_type === "add" && (
                  <span>+{formatNumber(item?.amount)}đ</span>
                )}
                {item?.entity_type === "sub" && (
                  <span>-{formatNumber(item?.amount)}đ</span>
                )}
              </Col>
            </Row>
            <span>{formatDate(item?.created_at, "DD/MM/YYYY")}</span>
          </div>
        ))}
      </div>
    </details>
  );
});

export default CardVisa;
