import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import MobileFooter from "_common/component/Layout/Footer/MobileFooter";
import FindOutPopup from "_common/component/Popup/FindOutPopup";
import FindOut1 from "./FindOut1";
import FindOut2 from "./FindOut2";
import FindOut3 from "./FindOut3";
import FindOut4 from "./FindOut4";
import FindOut5 from "./FindOut5";

import layer1 from "../../assets/img/Home/Layer-1.png";
import layer2 from "../../assets/img/Home/Layer-2.png";
import layer3 from "../../assets/img/Home/Layer-3.png";
import layer4 from "../../assets/img/Home/Layer-4.png";
import layer5 from "../../assets/img/Home/Layer-5.png";
import layer6 from "../../assets/img/Home/Layer-6.png";
import layer7 from "../../assets/img/Home/Layer-7.png";
import layer8 from "../../assets/img/Home/Layer-8.png";

const Home = observer(() => {
  const navigate = useNavigate();

  const [isShowFindOutPopup, setIsShowFindOutPopup] = useState(false);
  const [findOutContent, setFindOutContent] = useState(undefined);

  const handleShowRegisterPopup = () => {
    navigate("/auth/registration");
  };

  const handleShowFindOutPopup = (content = undefined) => {
    setFindOutContent(content);
    setIsShowFindOutPopup(!isShowFindOutPopup);
  };

  return (
    <>
      <HeaderTop />
      <div id="top-view" className="sc-pRTZB cgrjKw" />
      <div className="sc-qapaw iJULWE">
        <div className="sc-oTmZL gdlsIH">
          <picture className="sc-qQMSE bgoLuJ">
            <source srcSet={layer1} type="image/png" />
            <img src={layer1} alt="visual1" />
          </picture>
          <Button
            className="sc-AxhCb sc-pBolk dgyJhl root"
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            disableElevation
            onClick={handleShowRegisterPopup}
          >
            Mở thẻ ngay
          </Button>
          <div className="sc-prorn ghUMve">
            <div
              id="gads-ht"
              style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
            >
              <picture className="sc-qQMSE bgoLuJ">
                <source srcSet={layer2} type="image/png" />
                <img src={layer2} alt="subVisual1" />
              </picture>
            </div>
          </div>
          <Button
            className="sc-pReKu hCZaqv root"
            variant="text"
            size="small"
            startIcon={
              <svg
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: "0.5rem" }}
              >
                <rect
                  x="0.75"
                  y="2"
                  width="25"
                  height="25"
                  rx="12.5"
                  fill="#f58220"
                />
                <path
                  d="M14.7461 15.5977H19.4805V13.6289H14.7461V8.73047H12.7656V13.6289H8.04297V15.5977H12.7656V20.7422H14.7461V15.5977Z"
                  fill="white"
                />
              </svg>
            }
            onClick={() => handleShowFindOutPopup(<FindOut1 />)}
          >
            <h6 className="sc-fzqMdD sc-pZaHX hdqNpW">Tìm hiểu thêm</h6>
          </Button>
        </div>
        <div className="sc-oTmZL gdlsIH">
          <div id="gads-kcmtn" style={{ padding: "1rem 1.5rem" }}>
            <picture className="sc-qQMSE bgoLuJ">
              <source srcSet={layer3} type="image/png" />
              <img src={layer3} alt="visual2" />
            </picture>
          </div>
        </div>
        <div className="sc-oTmZL kniiKj">
          <div id="gads-sdn">
            <picture className="sc-qQMSE bgoLuJ">
              <source srcSet={layer4} type="image/png" />
              <img src={layer4} alt="visual3" />
            </picture>
            <IconButton
              className="sc-pReKu bxxWUc root"
              size="small"
              onClick={() => handleShowFindOutPopup(<FindOut2 />)}
            >
              <svg
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.75"
                  y="2"
                  width="25"
                  height="25"
                  rx="12.5"
                  fill="#f58220"
                />
                <path
                  d="M14.7461 15.5977H19.4805V13.6289H14.7461V8.73047H12.7656V13.6289H8.04297V15.5977H12.7656V20.7422H14.7461V15.5977Z"
                  fill="white"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <div className="sc-oTmZL kniiKj">
          <div id="gads-tg0">
            <picture className="sc-qQMSE bgoLuJ">
              <source srcSet={layer5} type="image/png" />
              <img src={layer5} alt="visual4" />
            </picture>
            <IconButton
              className="sc-pReKu bxxWUc root"
              size="small"
              onClick={() => handleShowFindOutPopup(<FindOut3 />)}
            >
              <svg
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.75"
                  y="2"
                  width="25"
                  height="25"
                  rx="12.5"
                  fill="#f58220"
                />
                <path
                  d="M14.7461 15.5977H19.4805V13.6289H14.7461V8.73047H12.7656V13.6289H8.04297V15.5977H12.7656V20.7422H14.7461V15.5977Z"
                  fill="white"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <div className="sc-oTmZL kniiKj">
          <div id="gads-mptn" style={{ padding: "1rem 1.5rem" }}>
            <picture className="sc-qQMSE bgoLuJ">
              <source srcSet={layer6} type="image/png" />
              <img src={layer6} alt="visual5" />
            </picture>
            <IconButton
              className="sc-pReKu bxxWUc root"
              size="small"
              onClick={() => handleShowFindOutPopup(<FindOut4 />)}
              style={{ top: "21rem", bottom: "unset" }}
            >
              <svg
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.75"
                  y="2"
                  width="25"
                  height="25"
                  rx="12.5"
                  fill="#f58220"
                />
                <path
                  d="M14.7461 15.5977H19.4805V13.6289H14.7461V8.73047H12.7656V13.6289H8.04297V15.5977H12.7656V20.7422H14.7461V15.5977Z"
                  fill="white"
                />
              </svg>
            </IconButton>
            <IconButton
              className="sc-pReKu bxxWUc root"
              size="small"
              onClick={() => handleShowFindOutPopup(<FindOut5 />)}
            >
              <svg
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.75"
                  y="2"
                  width="25"
                  height="25"
                  rx="12.5"
                  fill="#f58220"
                />
                <path
                  d="M14.7461 15.5977H19.4805V13.6289H14.7461V8.73047H12.7656V13.6289H8.04297V15.5977H12.7656V20.7422H14.7461V15.5977Z"
                  fill="white"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <div className="sc-oTmZL gdlsIH">
          <div id="gads-uddq" style={{ padding: "6rem 1.5rem" }}>
            <picture className="sc-qQMSE bgoLuJ">
              <source srcSet={layer7} type="image/png" />
              <img src={layer7} alt="visual7" />
            </picture>
            <IconButton
              className="sc-pReKu bxxWUc root"
              size="small"
              onClick={() => handleShowFindOutPopup(<FindOut1 />)}
            >
              <svg
                width="26"
                height="27"
                viewBox="0 0 26 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.75"
                  y="2"
                  width="25"
                  height="25"
                  rx="12.5"
                  fill="#f58220"
                />
                <path
                  d="M14.7461 15.5977H19.4805V13.6289H14.7461V8.73047H12.7656V13.6289H8.04297V15.5977H12.7656V20.7422H14.7461V15.5977Z"
                  fill="white"
                />
              </svg>
            </IconButton>
          </div>
        </div>
        <div className="sc-oTmZL gdlsIH">
          <picture className="sc-qQMSE bgoLuJ" style={{ paddingTop: "1rem" }}>
            <source srcSet={layer8} type="image/png" />
            <img src={layer8} alt="visual8" />
          </picture>
          <Button
            className="sc-AxhCb sc-pBolk sc-pJkiN eBsRxp root"
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            disableElevation
            onClick={handleShowRegisterPopup}
          >
            Mở thẻ ngay
          </Button>
        </div>
      </div>
      <FindOutPopup
        isOpenModal={isShowFindOutPopup}
        handleOpen={handleShowFindOutPopup}
        content={findOutContent}
      />
      <MobileFooter />
    </>
  );
});

export default Home;
