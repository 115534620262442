import React from "react";
import { BellFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import classNames from "classnames";

function WalletHeader({ title, isShowBack = false, showUser = false }) {
  const navigate = useNavigate();
  const {
    authStore: { user },
  } = useStores();

  return (
    <div
      className={classNames("header-page", {
        "header-home": showUser,
      })}
    >
      {isShowBack && (
        <span className="header-back" onClick={() => navigate(-1)} />
      )}
      {showUser && (
        <div className="info">
          <p>Xin chào,</p>
          <p>{user?.first_name}</p>
        </div>
      )}
      <p>{title}</p>
      <BellFilled
        style={{
          fontSize: "1.25rem",
          position: "absolute",
          right: "5%",
          bottom: "20%",
          transform: "translate(-50%, -50%)",
        }}
        onClick={() => navigate("/wallet/notify")}
      />
    </div>
  );
}

export default WalletHeader;
