import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Button, Form, InputNumber, Modal } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentApi } from "states/api/payment";
import { useNotify } from "_common/component/NotifyProvider";
import { formatCurrency } from "_common/utils/formatValue";

const WithdrawPopup = observer(
  ({ isModalOpen, handleCancel, handleRefetchDisbursementDetail }) => {
    const {
      authStore: { general, user },
      toastStore,
    } = useStores();
    const queryClient = useQueryClient();
    const { pushNotify } = useNotify();

    const { mutate, isLoading } = useMutation(
      (variables) =>
        PaymentApi.withdraw({
          params: {
            ...variables,
            bank_id: user?.bank_name,
            amount: variables.valueWithdraw,
            token_id: 4,
            type: "withdraw",
          },
        }),
      {
        onSuccess: (res) => {
          handleCancel();
          toastStore.success(res?.msg || "Thao tác thành công");
          queryClient.invalidateQueries(["get_profile"]);
          pushNotify({
            msg: "Có một đơn rút tiền mới",
            type: "deposit",
            url: "/history/withdrawal",
            target: [user?.user_ref?.id],
          });
          handleRefetchDisbursementDetail();
        },
        onError: (error) => {
          toastStore.error(error?.message ?? "Vui lòng thử lại!");
        },
      }
    );

    const minAmount = useMemo(
      () => (general?.min_withdraw ? Number(general?.min_withdraw) : 10000),
      [general]
    );

    const maxAmount = useMemo(
      () => (general?.max_withdraw ? Number(general?.max_withdraw) : 100000000),
      [general]
    );

    const handleWithdraw = (values) => {
      if (isLoading) return;
      mutate(values);
    };

    return (
      <Modal
        className="withdraw-popup"
        title="Rút tiền"
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={411}
      >
        <Form layout="vertical" onFinish={handleWithdraw} autoComplete="off">
          <Form.Item
            name="valueWithdraw"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập số tiền cần rút",
              },
              ({ getFieldValue }) => ({
                validator() {
                  if (getFieldValue("valueWithdraw") < minAmount) {
                    return Promise.reject(
                      new Error(
                        `Vui lòng nhập số tiền lớn hơn ${formatCurrency(
                          minAmount
                        )}`
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
              ({ getFieldValue }) => ({
                validator() {
                  if (getFieldValue("valueWithdraw") > maxAmount) {
                    return Promise.reject(
                      new Error(
                        `Vui lòng nhập số tiền nhỏ hơn ${formatCurrency(
                          maxAmount
                        )}`
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              placeholder="Nhập số tiền"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                id="btn-next"
                htmlType="submit"
                className="btn-withdraw"
                loading={isLoading}
              >
                Rút tiền
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default WithdrawPopup;
