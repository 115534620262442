import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { formatCardNumber } from "_common/utils/helper";
import { Button, Col, Row } from "antd";
import { optionBank2 } from "_common/constants/bank_2";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CardVisa from "./CardVisa";

import cardImg from "../../assets/img/CardWallet/Card_Holder.png";

const CardBankPage = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="card-wallet-page">
      <WalletHeader title="Ví thẻ" isShowBack />
      <div className="header-page-overlay" />
      <div className="card-wallet-container">
        <div className="card-container">
          <div className="card-front">
            <img className="card-image" src={cardImg} alt="Card Holder" />
            <div className="card-number">
              {formatCardNumber(get(user, "cards[0].card_number", ""))}
            </div>
            <div className="card-valid-day">
              {get(user, "cards[0].date_expire", "")}
            </div>
            <div className="card-name">
              {get(user, "cards[0].card_owner", "")?.toUpperCase()}
            </div>
          </div>
        </div>
        <div className="card-info-container">
          <details className="bank-link-container">
            <summary className="credit-card">
              <p style={{ fontSize: "1.25rem" }}>Liên kết ngân hàng</p>
              <p
                style={{
                  fontSize: "1.7rem",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                <img
                  src={
                    optionBank2.filter(
                      (bank) => bank.value === user?.bank_name
                    )[0]?.image
                  }
                  alt=""
                />
              </p>
              <p>
                {user?.bank_user_name?.toUpperCase()}/
                {user?.bank_number_display}
              </p>
            </summary>
            <div className="bank-link-container">
              <div className="credit-card-info" style={{ marginTop: "1rem" }}>
                <div className="item">
                  <p>
                    <b>Tên ngân hàng</b>
                  </p>
                  <p>{user?.bank_name}</p>
                </div>
                <div className="item">
                  <p>
                    <b>Số tài khoản</b>
                  </p>
                  <p> {user?.bank_account}</p>
                </div>
                <div className="item">
                  <p>
                    <b>Tên thụ hưởng</b>
                  </p>
                  <p> {user?.bank_user_name?.toUpperCase()}</p>
                </div>
              </div>
            </div>
          </details>
        </div>
      </div>
    </div>
  );
});

export default CardBankPage;
