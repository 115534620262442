import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";

import PersonIcon from "@mui/icons-material/Person";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";

const Me = observer(() => {
  const {
    authStore: { user, clear },
    toastStore,
  } = useStores();
  const navigate = useNavigate();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
      navigate("/");
    },
    onError: (error) => {
      clear();
      toastStore.error(error?.message ?? "Vui lòng thử lại!");
    },
  });

  return (
    <div className="me-page">
      <WalletHeader title="Tài khoản của tôi" />
      <div className="header-page-overlay" />
      <div className="me-container">
        <div className="me-info">
          <div className="me-icon">
            {user?.avatar_url ? (
              <img src={user?.avatar_url} alt="User Avatar" />
            ) : (
              <PersonIcon />
            )}
          </div>
          {user?.username}
        </div>
        <nav className="me-nav">
          <Link to="info" className="me-nav-item">
            <SettingsOutlinedIcon />
            Thông tin tài khoản
          </Link>
          <Link to="/wallet/me/card" className="me-nav-item">
            <LocalAtmIcon />
            Khoản thẻ của tôi
          </Link>
          <Link to="/wallet/me/card/status" className="me-nav-item">
            <DataThresholdingOutlinedIcon />
            Trạng thái thẻ
          </Link>
          {user?.sale_fb_link && (
            <a
              href={user?.sale_fb_link}
              target="_blank"
              rel="noreferrer"
              className="me-nav-item"
            >
              <HeadsetMicOutlinedIcon />
              Giải đáp thắc mắc
            </a>
          )}
        </nav>
        <div className="me-logout">
          <Button onClick={onLogout}>Đăng xuất</Button>
        </div>
        <div className="me-get-app">BankCredits v1.0.0</div>
      </div>
    </div>
  );
});

export default Me;
