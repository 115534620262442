import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { formatCurrency } from "_common/utils/formatValue";
import { Badge, Col, Row } from "antd";
import { observer } from "mobx-react-lite";
import { PaymentApi } from "states/api/payment";
import {
  paymentStatusText,
  paymentTypeText,
} from "_common/constants/statusType";
import { formatDate } from "_common/utils/helper";

const WalletHistory = observer(() => {
  const [params, setParams] = useState({});
  const { data, refetch } = useQuery(
    ["payment", "transaction", params],
    () =>
      PaymentApi.getList({
        params: {
          type: "all",
          is_self: 0,
          page: 1,
          limit: 20,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="card-wallet-page">
      <WalletHeader title="Lịch sử giao dịch" />
      <div className="header-page-overlay" />
      <div className="card-wallet-container">
        <div className="history-list">
          {data?.data?.map((item) => (
            <div className="history-item" key={item?.id}>
              <Row style={{ marginBottom: "0.25rem" }}>
                <Col
                  span={12}
                  style={{ fontSize: "1rem", fontWeight: "500", color: "grey" }}
                >
                  <span className={`history-item-type ${item?.type}`}>
                    {paymentTypeText[item?.type]?.toUpperCase()}
                  </span>
                </Col>
                <Col
                  span={12}
                  style={{
                    textAlign: "right",
                    fontSize: "1.15rem",
                    fontWeight: "700",
                    color: "grey",
                  }}
                >
                  {formatCurrency(item?.final_total, " ")}
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ fontWeight: "500", color: "grey" }}>
                  <Row>
                    {formatDate(item?.created_at, "DD/MM/YYYY HH:mm:ss")}
                  </Row>
                  <Row>{item?.content}</Row>
                  <Row>
                    {item?.status === "error" && (
                      <p className="text-red">{item?.note}</p>
                    )}
                  </Row>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Badge
                    className={item?.status}
                    count={paymentStatusText[item?.status]}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default WalletHistory;
