import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Form, Input, Radio, Button } from "antd";
import { useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";

const Step1 = observer(() => {
  const { toastStore } = useStores();
  const {
    registrationStore: { dataStep1, updateDataStep1 },
  } = useStores();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue(dataStep1);
  }, [form, dataStep1]);

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.checkAccount(variables),
    {
      onSuccess: (res, variables) => {
        console.log(res);
        if (res?.code === 200) {
          updateDataStep1(variables);
          navigate("/auth/registration/step-2");
        }
      },
      onError: (error) => {
        toastStore.error(error?.message ?? "Vui lòng thử lại!");
      },
    }
  );

  const handleSubmit = (value) => {
    if (isLoading) return;
    mutate(value);
  };

  return (
    <section id="step-body">
      <div className="wrapper step">
        <div className="content">
          <div className="max-720 m-auto outner-form form-banner">
            <div className="banner-form-container" id="banner-form">
              <div
                className="credit_card_type"
                id="vib-onlineplus-2in1"
                style={{ display: "block" }}
              >
                <div className="content-banner-top-form" />
                <div
                  className="txt_r_l"
                  style={{ textAlign: "center", padding: "1rem 0" }}
                >
                  <h3>PG PETROLIMEX 2in1</h3>
                  <p dir="ltr">Hoàn đến 10% cho chi tiêu trực tuyến</p>
                </div>
              </div>
            </div>
            <Form
              form={form}
              layout="vertical"
              initialValues={dataStep1}
              onFinish={handleSubmit}
              autoComplete="off"
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "1rem",
                marginTop: "1rem",
              }}
            >
              <Form.Item>
                <p className="txt_r_l">
                  Đăng ký thẻ tín dụng PG PETROLIMEX 2in1
                </p>
              </Form.Item>
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn giới tính",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="Nam">Nam</Radio>
                  <Radio value="Nữ">Nữ</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Nhập số điện thoại"
                name="username"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại",
                  },
                  {
                    min: 10,
                    message: "Số điện thoại chỉ bao gồm 10 số",
                  },
                  {
                    max: 10,
                    message: "Số điện thoại chỉ bao gồm 10 số",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Mật khẩu"
                name="password"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mật khẩu",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Nhập lại mật khẩu"
                name="confirm_password"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập lại mật khẩu",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Mật khẩu không trùng khớp")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                Bằng việc nhấn tiếp tục, bạn đồng ý Điều khoản điều kiện phát
                hành thẻ và Biểu phí&nbsp;
                <a
                  target="_blank"
                  href="/vn/the-tin-dung/vib-online-plus-2in1/bieu-phi-va-dieu-kien"
                  className="new-style-link"
                >
                  tại đây
                </a>
              </Form.Item>
              <Form.Item>
                <div className="text-right">
                  <Button
                    id="btn-next"
                    htmlType="submit"
                    className="btn btn_m yellow-btn"
                  >
                    <span>Tiếp tục</span>
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
          <div className="form-footer">
            <div className="row align-items-center pl-3">
              <div className="col-auto p-0">
                <span className="icon_shield_protected " />
              </div>
              <div className="col f-bold-14 p-0">
                PG PRETROLIMEX cam kết bảo mật thông tin khách hàng
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Step1;
