import { useStores } from "_common/hooks";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ToastType } from "states/stores/toastStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./MobileToast.scss";

const MobileToast = observer(() => {
  const {
    toastStore: { toast, clear },
  } = useStores();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (toast.text && toast.text !== "") {
      setIsShow(true);

      setTimeout(() => {
        setIsShow(false);
        clear();
      }, toast.autoClose);
    }
  }, [toast.text]);

  return (
    <div className="mobile-toast-container">
      {isShow && (
        <div className={`toast ${toast.type}`}>
          {toast.type === ToastType.SUCCESS && (
            <FontAwesomeIcon icon={faCheckCircle} size="3x" />
          )}
          {toast.type === ToastType.INFO && (
            <FontAwesomeIcon icon={faInfoCircle} size="3x" />
          )}
          {toast.type === ToastType.ERROR && (
            <FontAwesomeIcon icon={faExclamationCircle} size="3x" />
          )}
          {toast.text}
        </div>
      )}
    </div>
  );
});

export default MobileToast;
