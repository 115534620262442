import React from "react";
import { Routes, Route } from "react-router-dom";

import BasicLayout from "_common/component/Layout/BasicLayout";
import UnAuthLayout from "_common/component/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

import CheckAuth from "routers/CheckAuth";
import { MiddlewareRouter } from "routers/MiddlewareRouter";
// auth
import HomePage from "pages/Home";
import CreditCardRegistration from "pages/CreditCardRegistration";
import Step1 from "pages/CreditCardRegistration/Step1";
import Step2 from "pages/CreditCardRegistration/Step2";
import Step3 from "pages/CreditCardRegistration/Step3";
import Step4 from "pages/CreditCardRegistration/Step4";
import Step5 from "pages/CreditCardRegistration/Step5";
import Step6 from "pages/CreditCardRegistration/Step6";
import Login from "pages/Login";
import CardWallet from "pages/CardWallet";
import Me from "pages/Me";
import WalletLayout from "_common/component/Layout/BasicLayout/WalletLayout";
import WalletHome from "pages/WalletHome";
import WalletHistory from "pages/WalletHistory";
import MeInfo from "pages/Me/MeInfo";
import BankSelect from "pages/CreditCardRegistration/BankSelect";
import MeInfoPersonal from "pages/Me/MeInfo/MeInfoPersonal";
import MeInfoBank from "pages/Me/MeInfo/MeInfoBank";
import MeInfoRelative from "pages/Me/MeInfo/MeInfoRelative";
import MeInfoVerify from "pages/Me/MeInfo/MeInfoVerify";
import MeInfoAdditional from "pages/Me/MeInfo/MeInfoAdditional";
import MeInfoSignature from "pages/Me/MeInfo/MeInfoSignature";
import CardStatusInfo from "pages/Me/CardInfo/CardStatusInfo";
import MyCardInfo from "pages/Me/CardInfo/MyCardInfo";
import CardBankPage from "pages/CardWallet/CardBankPage";
import Notify from "pages/Notify";

export function Routers() {
  return (
    <Routes>
      <Route path="/" element={<BasicLayout />}>
        <Route index element={<HomePage />} />
        <Route path="home" element={<HomePage />} />
      </Route>
      <Route
        path="/wallet"
        element={
          <MiddlewareRouter>
            <WalletLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<WalletHome />} />
        <Route path="card" element={<CardWallet />} />
        <Route path="card/bank" element={<CardBankPage />} />
        <Route path="history" element={<WalletHistory />} />
        <Route path="notify" element={<Notify />} />
        <Route path="me">
          <Route index element={<Me />} />
          <Route path="info">
            <Route index element={<MeInfo />} />
            <Route path="detail" element={<MeInfoPersonal />} />
            <Route path="verify" element={<MeInfoVerify />} />
            <Route path="bank" element={<MeInfoBank />} />
            <Route path="relative" element={<MeInfoRelative />} />
            <Route path="additional" element={<MeInfoAdditional />} />
            <Route path="signature" element={<MeInfoSignature />} />
          </Route>
          <Route path="card">
            <Route index element={<MyCardInfo />} />
            <Route path="status" element={<CardStatusInfo />} />
          </Route>
        </Route>
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<CreditCardRegistration />}>
          <Route index element={<Step1 />} />
          <Route path="step-2" element={<Step2 />} />
          <Route path="step-3" element={<Step3 />} />
          <Route path="step-4" element={<Step4 />} />
          <Route path="step-5" element={<Step5 />} />
          <Route path="success" element={<Step6 />} />
          <Route path="bank-select" element={<BankSelect />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
