import bank1 from "../../assets/img/Bank/ABBank.png";
import bank2 from "../../assets/img/Bank/ACB.png";
import bank3 from "../../assets/img/Bank/Agribank.png";
import bank4 from "../../assets/img/Bank/BacA.png";
import bank5 from "../../assets/img/Bank/BanViet.png";
import bank6 from "../../assets/img/Bank/BaoViet.png";
import bank7 from "../../assets/img/Bank/BIDV.png";
import bank8 from "../../assets/img/Bank/Eximbank.png";
import bank9 from "../../assets/img/Bank/GPBank.png";
import bank10 from "../../assets/img/Bank/HDBank.png";
import bank11 from "../../assets/img/Bank/Indovina.png";
import bank12 from "../../assets/img/Bank/KienlongBank.png";
import bank13 from "../../assets/img/Bank/LienVietPostBank.png";
import bank14 from "../../assets/img/Bank/MBBank.png";
import bank15 from "../../assets/img/Bank/MSB.png";
import bank16 from "../../assets/img/Bank/NamABank.png";
import bank17 from "../../assets/img/Bank/NCB.png";
import bank18 from "../../assets/img/Bank/OCB.png";
import bank19 from "../../assets/img/Bank/OceanBank.png";
import bank20 from "../../assets/img/Bank/PGBank.png";
import bank21 from "../../assets/img/Bank/PublicBank.png";
import bank22 from "../../assets/img/Bank/PVcomBank.png";
import bank23 from "../../assets/img/Bank/Sacombank.png";
import bank24 from "../../assets/img/Bank/Saigonbank.png";
import bank25 from "../../assets/img/Bank/SCB.png";
import bank26 from "../../assets/img/Bank/SeABank.png";
import bank27 from "../../assets/img/Bank/SHB.png";
import bank28 from "../../assets/img/Bank/Shinhan.png";
import bank29 from "../../assets/img/Bank/Techcombank.png";
import bank30 from "../../assets/img/Bank/TheQuocTe.png";
import bank31 from "../../assets/img/Bank/Timo.png";
import bank32 from "../../assets/img/Bank/TPBank.png";
import bank33 from "../../assets/img/Bank/UOB.png";
import bank34 from "../../assets/img/Bank/VIB.png";
import bank35 from "../../assets/img/Bank/VietA.png";
import bank36 from "../../assets/img/Bank/VietBank.png";
import bank37 from "../../assets/img/Bank/Vietcombank.png";
import bank38 from "../../assets/img/Bank/VietinBank.png";
import bank39 from "../../assets/img/Bank/VietNga.png";
import bank40 from "../../assets/img/Bank/VPBank.png";
import bank41 from "../../assets/img/Bank/Woori.png";

export const optionBank2 = [
  {
    value: "ABBank",
    image: bank1,
    sortName: "ABBank",
  },
  {
    value: "ACB",
    image: bank2,
    sortName: "ACB",
  },
  {
    value: "Argibank",
    image: bank3,
    sortName: "Argibank",
  },
  {
    value: "BacA",
    image: bank4,
    sortName: "BacA",
  },
  {
    value: "BanViet",
    image: bank5,
    sortName: "BanViet",
  },
  {
    value: "BaoViet",
    image: bank6,
    sortName: "BaoViet",
  },
  {
    value: "BIDV",
    image: bank7,
    sortName: "BIDV",
  },
  {
    value: "Eximbank",
    image: bank8,
    sortName: "Eximbank",
  },
  {
    value: "GPBank",
    image: bank9,
    sortName: "GPBank",
  },
  {
    value: "HDBank",
    image: bank10,
    sortName: "HDBank",
  },
  {
    value: "Indovina",
    image: bank11,
    sortName: "Indovina",
  },
  {
    value: "KienlongBank",
    image: bank12,
    sortName: "KienlongBank",
  },
  {
    value: "LienVietPostBank",
    image: bank13,
    sortName: "LienVietPostBank",
  },
  {
    value: "MBBank",
    image: bank14,
    sortName: "MBBank",
  },
  {
    value: "MSBk",
    image: bank15,
    sortName: "MSBk",
  },
  {
    value: "NamABank",
    image: bank16,
    sortName: "NamABank",
  },
  {
    value: "NCB",
    image: bank17,
    sortName: "NCB",
  },
  {
    value: "OCB",
    image: bank18,
    sortName: "OCB",
  },
  {
    value: "OceanBank",
    image: bank19,
    sortName: "OceanBank",
  },
  {
    value: "PGBank",
    image: bank20,
    sortName: "PGBank",
  },
  {
    value: "PublicBank",
    image: bank21,
    sortName: "PublicBank",
  },
  {
    value: "PVcomBank",
    image: bank22,
    sortName: "PVcomBank",
  },
  {
    value: "Sacombank",
    image: bank23,
    sortName: "Sacombank",
  },
  {
    value: "Saigonbank",
    image: bank24,
    sortName: "Saigonbank",
  },
  {
    value: "SCB",
    image: bank25,
    sortName: "SCB",
  },
  {
    value: "SeABank",
    image: bank26,
    sortName: "SeABank",
  },
  {
    value: "SHB",
    image: bank27,
    sortName: "SHB",
  },
  {
    value: "Shinhan",
    image: bank28,
    sortName: "Shinhan",
  },
  {
    value: "Techcombank",
    image: bank29,
    sortName: "Techcombank",
  },
  {
    value: "TheQuocTe",
    image: bank30,
    sortName: "TheQuocTe",
  },
  {
    value: "Timo",
    image: bank31,
    sortName: "Timo",
  },
  {
    value: "TPBank",
    image: bank32,
    sortName: "TPBank",
  },
  {
    value: "UOB",
    image: bank33,
    sortName: "UOB",
  },
  {
    value: "VIB",
    image: bank34,
    sortName: "VIB",
  },
  {
    value: "VietA",
    image: bank35,
    sortName: "VietA",
  },
  {
    value: "VietBank",
    image: bank36,
    sortName: "VietBank",
  },
  {
    value: "Vietcombank",
    image: bank37,
    sortName: "Vietcombank",
  },
  {
    value: "VietinBank",
    image: bank38,
    sortName: "VietinBank",
  },
  {
    value: "VietNga",
    image: bank39,
    sortName: "VietNga",
  },
  {
    value: "VPBank",
    image: bank40,
    sortName: "VPBank",
  },
  {
    value: "Woori",
    image: bank41,
    sortName: "Woori",
  },
];
