import React from "react";
import "react-notifications/lib/notifications.css";
import "antd/dist/reset.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "swiper/swiper-bundle.css";
import "./assets/scss/style.scss";
import Root from "./Root";

function App() {
  return <Root />;
}

export default App;
