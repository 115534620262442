import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";

const MeInfoSignature = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="me-info-detail-page">
      <WalletHeader title="Chữ ký" isShowBack />
      <div className="header-page-overlay" />
      <div className="me-info-detail-container">
        <div className="detail-box">
          <div className="detail-title">Chữ ký</div>
          <div className="detail-info">
            <img src={user?.sign_image} alt="User Signature" />
          </div>
        </div>
      </div>
    </div>
  );
});

export default MeInfoSignature;
