export const statusWorkText = {
  0: "Tất cả",
  1: "Đang làm việc",
  2: "Nghỉ chờ việc",
  3: "Nghỉ tự túc",
  4: "Nghỉ việc",
};

export const statusWorkColor = {
  1: "#33cc33",
  2: "#e6b800",
  3: "#660033",
  4: "#cc0000",
};

export const laborContractTypeText = {
  4: "Xác định thời điểm",
  5: "Không xác định",
};

export const genderText = {
  1: "Nam",
  2: "Nữ",
};

export const statusText = {
  0: "Ngừng hoạt động",
  1: "Hoạt động",
};

export const statusColor = {
  0: "#cc0000",
  1: "#33cc33",
};

export const permissionText = {
  1: "Quản trị viên",
  2: "Người dùng",
};

export const paymentStatusText = {
  pending: "Đang chờ",
  complete: "Hoàn thành",
  error: "Từ chối",
};
export const DiceText = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
};

export const gameBetText = {
  big: "Lớn",
  small: "Nhỏ",
  odd: "Lẻ",
  even: "Chẵn",
};

export const gameStatusText = {
  win: "Trúng thưởng",
  loss: "Chưa chiến thắng",
  betGame: "Đang chờ",
};

export const orderStatusColor = {
  complete: "green",
  error: "red",
  new: "black",
  no_profit: "blue",
  cancel: "blueviolet",
};

export const orderTypeText = {
  order: "Đơn hàng",
  gift: "Quà tặng",
  bonus: "Ưu đãi",
};

export const orderStatusText = {
  complete: "Hoàn thành",
  no_profit: "Không giảm giá",
  error: "Từ chối",
  new: "Đơn mới",
  cancel: "Vô hiệu",
};

export const paymentTypeText = {
  loan: "Mở thẻ tín dụng",
  system: "Hệ thống",
  deposit: "Nạp tiền",
  commission: "Hoa hồng",
  withdraw: "Rút tiền",
  transfer: "Chuyển tiền",
  order: "Đặt hàng",
  gift: "Quà tặng",
  new_member: "Quà đăng ký hậu trường",
  new_ref: "Đăng ký phần thưởng đội",
  bonus: "Phần thưởng",
  order_back: "Hoàn tiền hàng",
};

export const levelText = [
  "BASIC",
  "BRONZE",
  "SILVER",
  "GOLD",
  "PLATINUM",
  "DIAMOND",
  "DIAMOND_PLUS",
];
