import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { formatCardNumber } from "_common/utils/helper";
import { get } from "lodash";
import { observer } from "mobx-react-lite";

import cardImg from "../../assets/img/CardWallet/Card_Holder.png";

const FrontCard = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="card-front">
      <img className="card-image" src={cardImg} alt="Card Holder" />
      <div className="card-number">
        {formatCardNumber(get(user, "cards[0].card_number", ""))}
      </div>
      <div className="card-valid-day">
        {get(user, "cards[0].date_expire", "")}
      </div>
      <div className="card-name">
        {get(user, "cards[0].card_owner", "")?.toUpperCase()}
      </div>
    </div>
  );
});

export default FrontCard;
