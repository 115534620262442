import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { optionBank2 } from "_common/constants/bank_2";

const BankSelect = observer(() => {
  const {
    registrationStore: { dataStep4, updateDataStep4 },
  } = useStores();

  const handleSelectBank = (bankValue) => {
    updateDataStep4({ ...dataStep4, bank_name: bankValue });
    window.history.back();
  };

  return (
    <div className="bank-select">
      <nav className="bank-select-nav">
        {optionBank2.map((bank) => (
          <div
            key={bank.value}
            className="nav-item"
            onClick={() => handleSelectBank(bank.value)}
          >
            <img src={bank.image} alt={bank.value} />
          </div>
        ))}
      </nav>
    </div>
  );
});

export default BankSelect;
