import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";
import { get } from "lodash";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";

const MyCardInfo = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="me-info-detail-page">
      <WalletHeader title="Khoản thẻ của tôi" isShowBack />
      <div className="header-page-overlay" />
      <div className="me-info-detail-container">
        <div className="detail-box">
          <div className="detail-title">Thông tin thẻ</div>
          <div className="detail-info">
            <table>
              <tr>
                <td>Mã hợp đồng</td>
                <td>{get(user, "loans[0].ref_no")}</td>
              </tr>
              <tr>
                <td>Hạn mức khả dụng thẻ</td>
                <td>{formatNumber(get(user, "loans[0].amount", 0))} VNĐ</td>
              </tr>
              <tr>
                <td>Sao kê hàng tháng</td>
                <td>
                  30/
                  {moment(get(user, "loans[0].created_at")).format("MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td>Kỳ hạn trả nợ</td>
                <td>
                  15/
                  {moment(get(user, "loans[0].created_at"))
                    .add(1, "M")
                    .format("MM/YYYY")}
                </td>
              </tr>
              <tr>
                <td>Ngày kí hợp đồng</td>
                <td>
                  {moment(get(user, "loans[0].created_at")).format(
                    "DD/MM/YYYY"
                  )}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MyCardInfo;
