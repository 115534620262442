import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";

const MeInfoRelative = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="me-info-detail-page">
      <WalletHeader title="Thông tin người liên hệ" isShowBack />
      <div className="header-page-overlay" />
      <div className="me-info-detail-container">
        <div className="detail-box">
          <div className="detail-title">Thông tin người liên hệ</div>
          <div className="detail-info">
            <table>
              <tr>
                <td>Họ và tên người thân 1</td>
                <td>{user?.relative_name}</td>
              </tr>
              <tr>
                <td>Số điện thoại người thân 1</td>
                <td>{user?.relative_phone}</td>
              </tr>
              <tr>
                <td>Mối quan hệ 1</td>
                <td>{user?.relative_type}</td>
              </tr>
              <tr>
                <td>Họ và tên người thân 2</td>
                <td>{user?.relative_name2}</td>
              </tr>
              <tr>
                <td>Số điện thoại người thân 2</td>
                <td>{user?.relative_phone_2}</td>
              </tr>
              <tr>
                <td>Mối quan hệ 2</td>
                <td>{user?.relative_type2}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MeInfoRelative;
