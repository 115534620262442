import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Outlet, useLocation, useNavigate } from "react-router";

const CreditCardRegistration = observer(() => {
  const {
    registrationStore: { initialStore },
  } = useStores();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    initialStore();
  }, []);

  const handleNavigate = (pathName) => {
    navigate(pathName);
  };

  return (
    <div className="credit-card-registration">
      <section
        className="vib-v2-section-header_new_form"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "center",
        }}
      >
        <div className="vib-v2-wrapper_new">
          <div className="vib-v2-logoVIB">
            <a href="/" title="" target="">
              <div className="box_logo_vib">
                <i className="txt_logo_vib" />
              </div>
            </a>
          </div>
        </div>
      </section>
      <section id="navigation">
        <div className="wrapper nav-step">
          <div className="content">
            <div className="max-720 m-auto">
              <div className="nav-step">
                <ul
                  style={{
                    display:
                      location.pathname !== "/auth/registration/success"
                        ? "flex"
                        : "none",
                  }}
                >
                  <li
                    id="li-s1"
                    className={
                      location.pathname === "/auth/registration" && "active"
                    }
                  >
                    <button
                      type="button"
                      className="step-number"
                      onClick={() => handleNavigate("/auth/registration")}
                    >
                      1
                    </button>
                    <span>
                      <span>Thông tin liên hệ</span>
                    </span>
                  </li>
                  <li
                    id="li-s2"
                    className={
                      location.pathname === "/auth/registration/step-2" &&
                      "active"
                    }
                  >
                    <button
                      type="button"
                      className="step-number"
                      onClick={() =>
                        handleNavigate("/auth/registration/step-2")
                      }
                    >
                      2
                    </button>
                    <span>
                      <span>Thông tin cơ bản</span>
                    </span>
                  </li>
                  <li
                    id="li-s3"
                    className={
                      location.pathname === "/auth/registration/step-3" &&
                      "active"
                    }
                  >
                    <button
                      type="button"
                      className="step-number"
                      onClick={() =>
                        handleNavigate("/auth/registration/step-3")
                      }
                    >
                      3
                    </button>
                    <span>
                      <span>Xác minh</span>
                    </span>
                  </li>
                  <li
                    id="li-s4"
                    className={
                      location.pathname === "/auth/registration/step-4" &&
                      "active"
                    }
                  >
                    <button
                      type="button"
                      className="step-number"
                      onClick={() =>
                        handleNavigate("/auth/registration/step-4")
                      }
                    >
                      4
                    </button>
                    <span>
                      <span>Thông tin ngân hàng</span>
                    </span>
                  </li>
                  <li
                    id="li-s5"
                    className={
                      location.pathname === "/auth/registration/step-5" &&
                      "active"
                    }
                  >
                    <button
                      type="button"
                      className="step-number"
                      onClick={() =>
                        handleNavigate("/auth/registration/step-5")
                      }
                    >
                      5
                    </button>
                    <span>
                      <span>Xác nhận chữ ký</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Outlet />
    </div>
  );
});

export default CreditCardRegistration;
