import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class RegistrationStore {
  dataStep1 = {};

  dataStep2 = {};

  dataStep3 = {};

  dataStep4 = {};

  dataStep5 = {};

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "RegistrationStore",
      properties: [
        "dataStep1",
        "dataStep2",
        "dataStep3",
        "dataStep4",
        "dataStep5",
      ],
      storage: window.localStorage,
    });
  }

  initialStore = () => {
    this.dataStep1 = {
      gender: null,
      username: "",
      password: "",
      confirm_password: "",
    };
    this.dataStep2 = {
      full_name: "",
      id_proof_number: "",
      dob: "",
      address: "",
      employ_status: null,
      job: "",
      salary: null,
      debit_reason: "",
      relative_name: "",
      relative_phone: "",
      relative_type: "",
      relative_name2: "",
      relative_phone_2: "",
      relative_type2: "",
    };
    this.dataStep3 = {
      front_image: "",
      back_image: "",
      self_image: "",
    };
    this.dataStep4 = {
      bank_name: "",
      bank_user_name: "",
      bank_account: "",
    };
    this.dataStep5 = {
      sign_image: "",
    };
  };

  updateDataStep1 = (value) => {
    this.dataStep1 = value;
  };

  updateDataStep2 = (value) => {
    this.dataStep2 = value;
  };

  updateDataStep3 = (value) => {
    this.dataStep3 = { ...this.dataStep3, ...value };
  };

  updateDataStep4 = (value) => {
    this.dataStep4 = value;
  };

  updateDataStep5 = (value) => {
    this.dataStep5 = value;
  };
}
