import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Form, Input, Select, Button } from "antd";
import { useNavigate } from "react-router";
import { formatDate } from "_common/utils/helper";

const Step2 = observer(() => {
  const {
    registrationStore: { dataStep2, updateDataStep2 },
  } = useStores();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue(dataStep2);
  }, [form, dataStep2]);

  const handleChangeBirthday = (event) => {
    form.setFieldsValue({ dob: formatDate(event.target.value) });
  };

  const handleSubmit = (value) => {
    updateDataStep2(value);

    navigate("/auth/registration/step-3");
  };

  return (
    <section id="step-body">
      <div className="wrapper step">
        <div className="content mb-40">
          <div className="max-720 m-auto outner-form">
            <Form
              form={form}
              layout="vertical"
              initialValues={dataStep2}
              onFinish={handleSubmit}
              autoComplete="off"
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "1rem",
                marginTop: "1rem",
              }}
            >
              <Form.Item>
                <p className="txt_r_l">Thông tin cơ bản</p>
              </Form.Item>
              <Form.Item
                label="Họ và Tên"
                name="full_name"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập họ và tên",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Số CMND/CCCD"
                name="id_proof_number"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số cmnd/cccd",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Ngày sinh"
                name="dob"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập ngày sinh",
                  },
                ]}
                help="DD/MM/YYYY"
              >
                <Input onChange={handleChangeBirthday} />
              </Form.Item>
              <Form.Item
                label="Địa chỉ nơi ở"
                name="address"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập địa chỉ nơi ở",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="employ_status"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn tình trạng việc làm",
                  },
                ]}
              >
                <Select
                  placeholder="Tình trạng việc làm"
                  options={[
                    { value: "Đang đi làm", label: "Đang đi làm" },
                    { value: "Kinh doanh", label: "Kinh doanh" },
                    { value: "Thất nghiệp", label: "Thất nghiệp" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Nghề nghiệp"
                name="job"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập nghề nghiệp",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="salary"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn thu nhập hàng tháng",
                  },
                ]}
              >
                <Select
                  placeholder="Thu nhập hàng tháng"
                  options={[
                    { value: "Dưới 5 triệu", label: "Dưới 5 triệu" },
                    { value: "Từ 5 đến 10 triệu", label: "Từ 5 đến 10 triệu" },
                    {
                      value: "Từ 10 đến 20 triệu",
                      label: "Từ 10 đến 20 triệu",
                    },
                    { value: "Trên 20 triệu", label: "Trên 20 triệu" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Lý do mở thẻ"
                name="debit_reason"
                className="variant-standrad"
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <p className="txt_r_l">Người liên hệ khi cần</p>
              </Form.Item>
              <Form.Item
                label="Họ và tên người thân 1"
                name="relative_name"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Số điện thoại người thân 1"
                name="relative_phone"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Mối quan hệ người thân 1"
                name="relative_type"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Họ và tên người thân 2"
                name="relative_name2"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Số điện thoại người thân 2"
                name="relative_phone_2"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Mối quan hệ người thân 2"
                name="relative_type2"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <div className="text-right">
                  <Button
                    id="btn-next"
                    htmlType="submit"
                    className="btn btn_m yellow-btn"
                  >
                    <span>Tiếp tục</span>
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Step2;
