import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";

const MeInfoAdditional = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="me-info-detail-page">
      <WalletHeader title="Dữ liệu bổ sung" isShowBack />
      <div className="header-page-overlay" />
      <div className="me-info-detail-container">
        <div className="detail-box">
          <div className="detail-title">Dữ liệu bổ sung</div>
          <div className="detail-info">
            <table>
              <tr>
                <td>Mục đích vay vốn</td>
                <td>{user?.debit_reason}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MeInfoAdditional;
