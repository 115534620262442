import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";

const MeInfoPersonal = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="me-info-detail-page">
      <WalletHeader title="Thông tin cá nhân" isShowBack />
      <div className="header-page-overlay" />
      <div className="me-info-detail-container">
        <div className="detail-box">
          <div className="detail-title">Thông tin cá nhân</div>
          <div className="detail-info">
            <table>
              <tr>
                <td>Họ tên</td>
                <td>{user?.first_name}</td>
              </tr>
              <tr>
                <td>Địa chỉ</td>
                <td>{user?.address}</td>
              </tr>
              <tr>
                <td>Số CMND/CCCD</td>
                <td>{user?.id_proof_number}</td>
              </tr>
              <tr>
                <td>Giới tính</td>
                <td>{user?.gender}</td>
              </tr>
              <tr>
                <td>Ngày sinh</td>
                <td>{user?.dob}</td>
              </tr>
              <tr>
                <td>Nghề nghiệp</td>
                <td>{user?.job}</td>
              </tr>
              <tr>
                <td>Thu nhập</td>
                <td>{user?.salary}</td>
              </tr>
              <tr>
                <td>Mục đích khoản vay</td>
                <td>{user?.debit_reason}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MeInfoPersonal;
