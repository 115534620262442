import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

const FindOut5 = observer(() => {
  const { authStore } = useStores();

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <h4 className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU">
        Miễn lãi lên đến 45 ngày
      </h4>
      Kể từ ngày tiêu dùng qua thẻ đến ngày đến hạn thanh toán của thẻ, Quý
      khách được TPBank hỗ trợ miễn lãi tối đa tới 45 ngày.
      <br />
      <br />
      Mọi chi tiết thêm về thể lệ chương trình, Khách hàng vui lòng tham khảo
      tại : website của TPBank:&nbsp;
      <a
        href="https://tpb.vn/"
        role="button"
        className="sc-AxhUy sc-qQmou dVBRWK"
      >
        http://www.tpb.vn&nbsp;
      </a>
      hoặc liên hệ hotline:&nbsp;
      <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
        1900 58 58 85
      </span>
      &nbsp;/&nbsp;
      <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
        1900 6036
      </span>
    </div>
  );
});

export default FindOut5;
