import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";

export default function MobileLayout() {
  return (
    <Layout className="sc-pbYBj gDaToN">
      <div id="layout-wrapper" className="sc-pAZqv cuOtHq">
        <div className="sc-qZtCU sc-oUcyK jGwMs">
          <Outlet />
        </div>
      </div>
    </Layout>
  );
}
