import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { formatCardNumber } from "_common/utils/helper";
import { get } from "lodash";
import { observer } from "mobx-react-lite";

import cardImg from "../../assets/img/CardWallet/backcard.png";

const BackCard = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="card-front">
      <img className="card-image" src={cardImg} alt="Card Holder" />
      <div className="card-valid-cvg">{get(user, "cards[0].cvg", "")}</div>
    </div>
  );
});

export default BackCard;
