/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { Button, Collapse } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";

import walletBanner1 from "../../assets/img/WalletHome/Banner_1.png";
import walletBanner2 from "../../assets/img/WalletHome/Banner_2.png";
import walletBanner3 from "../../assets/img/WalletHome/Banner_3.png";

const WalletHome = observer(() => (
  <div className="wallet-home-page">
    <WalletHeader title="Bản quyền thuộc về PG Bank" />
    <div className="header-page-overlay" />
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      centeredSlides
      loop
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      <SwiperSlide>
        <img src={walletBanner1} alt="" style={{ width: "100%" }} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={walletBanner2} alt="" style={{ width: "100%" }} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={walletBanner3} alt="" style={{ width: "100%" }} />
      </SwiperSlide>
    </Swiper>
    <div
      className="wallet-home-text"
      style={{
        margin: "3rem 0 2rem 0",
      }}
    >
      Bạn cần biết
    </div>
    <div className="wallet-home-faq">
      <Collapse accordion>
        <Collapse.Panel
          header={
            <div className="faq-item">
              <ArticleOutlinedIcon />
              Hợp đồng
            </div>
          }
        >
          <p className="text-center mb-0">CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
          <p className="text-center"> Độc Lập - Tự Do - Hạnh Phúc</p>
          <p className="text-center">
            <b>ĐIỀU KHOẢN & HỘP ĐỒNG THẺ TÍN DỤNG</b>
          </p>
          <p>
            QUY ĐỊNH CHUNG : PG được quản lý và vận hành hợp pháp bởi Công Ty
            TNTM tư vấn tài chính Dầu Khí ( Sau đây được gọi chung là PG BANK )
            Người sử dụng ứng dụng và các dịch vụ trên ứng dụng ( Sau đây có thể
            gọi là "Bạn" Hoặc "Khách Hàng") được yêu cầu phải đọc kỹ, hiểu, đồng
            ý và chịu sự ràng buộc với các quay định chung được mô tả trong Điều
            Khoản và Điều Kiện sử dụng đối với người sử dụng dịch vụ PGBANK (
            Sau đây gọi là " Điều khoản và Điều Kiện") Được áp dụng kể từ tháng
            09/2018 Theo các nội dung sau :
          </p>
          <p>
            Đây là bản điều kiện hợp đồng điện tử bao gồm chữ ký dành cho khách
            hàng vay Online .Để thể hiện tính minh bạch yêu cầu khách hàng vui
            lòng ký tên xác nhận sau khi đọc kỹ các điếu khoản và chấp nhận thỏa
            thuận nhầm đảm bảo và trách nhiệm trước pháp luật nếu vi phạm hợp
            đồng .
          </p>
          <p>Điều I :</p>
          <p>
            - Lãi suất tín dụng đối với thẻ tín dụng được ban hành là 1.2% mức
            lãi suất không thay đổi khi hợp đồng ký kết có hiệu lực với người
            tiêu dùng thẻ.Số tiền lãi cũng như tổng tiền phải thanh toán hàng
            tháng được hiền thị trong mục phần thông tin ứng dụng thẻ tín dụng
            này.
          </p>
          <p>
            Thanh toán trả góp cả gốc lẫn lãi tại Ngân Hàng, Cửa hàng Thế Giới
            Di Động DMX, Viettel trên ứng dụng tài khoản cá nhân, các ví điện tử
            MoMo
          </p>
          <p>Điều II : Chú Ý Khi Sử Dụng Thẻ Tín Dụng. </p>
          <p>
            - Cần có kế hoạch chi tiêu, phân chia các khoản thu nhập tài chính
            và chi tiêu rõ ràng đặt ra các khoản và giới hạn chi tiêu của thẻ
            tín dụng để tránh tình trạng chi tiêu quá đà, không có khả năng chi
            trả. Mỗi loại thẻ tín dụng đều có những hạn mức nhất định, bạn cần
            lưu ý hạn mức thanh toán để tránh chi tiêu quá mức.
          </p>
          <p>Điều III : Quyền Và Nghĩa Vụ.</p>
          <p>
            - Bên nhận : Được Công Ty cung cấp dịch vụ thẻ tín dụng tiêu dùng,
            được biết các thông tin về khoản vay như lãi suất, kỳ hạn và ngày
            thanh toán 15. Khách hàng có trách nhiệm thanh toán trước kỳ hạn và
            hoàn tất việc thanh toán toàn bộ số tiền cả gốc lẫn lãi vào đúng của
            kỳ hạn.
          </p>
          <p>Điều IV : Các Điều Khoản Khác.</p>
          <p>
            - Các điều khoản này được điều chỉnh bởi pháp luật hiện hành của
            nước CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM . Nếu bất kỳ đều khoản, điều
            kiện nào trong điều khoản nay hay bất kỳ phần nào hoặc khoản mục nào
            của tài liệu này tuyên bố là không có hiệu lực hay không thể cưỡng
            chế thi hành bời Cơ Quan Nhà Nước có thẩm quyền thì những điều khoản
            hay điều kiện này sẽ không làm vô hiệu các điều khoản này
          </p>
          <p>
            - Hợp đồng tín dụng có hiệu lực kể từ khi thẻ phát hành và khách
            hàng sử dụng tiền trong thẻ tín dụng đó. Trốn tránh trách nhiệm
            khách hàng sẽ chịu hoàn toàn với trách nhiệm hợp đồng khách hàng đã
            ký. Bản hợp đồng chỉ chấm dứt sau khi bên nhận thẻ hoàn thành nghĩa
            vụ thanh toán.
          </p>
          <p>
            - Công Ty không chịu trách nhiệm về các lỗi của khách hàng hay khách
            hàng lạm dụng thẻ tín dụng trong quá trình sử dụng.
          </p>
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <div className="faq-item">
              <InfoIcon />
              Giới thiệu
            </div>
          }
        >
          Phát sinh khoản chi tiêu ngoài kế hoạch? Mệt mỏi với những quy trình
          rườm rà của các hình thức cho vay khác? Thẻ tiêu dùng – chính là giải
          pháp dành cho bạn PGBank cung cấp dịch vụ tư vấn giải pháp tài chính
          tin cậy và là công cụ kết nối khách hàng với các đối tác cho vay thông
          qua thủ tục đơn giản, tức thì.
          <br />
          <br />
          Nhanh chóng Sau khi điền thông tin vào mẫu đơn – chúng tôi sẽ gọi điện
          cho bạn trong vòng 30 phút để thông báo số tiền được duyệt. Ngay sau
          đó bạn có thể chi tiêu hoặc rút về ngân hàng của mình.
          <br />
          <br />
          Đơn giản PGBank cung cấp dịch vụ thuận tiện, cho phép bạn dễ dàng yêu
          cầu khoản vay mà không cần phải đến chi nhánh.
          <br />
          <br />
          Minh bạch PGBank cung cấp đầy đủ và trung thực các thông tin về khoản
          vay, lãi và chi phí khác liên quan khi đăng ký khoản vay. Bạn sẽ được
          tư vấn tận tình và chu đáo bởi đội ngũ nhân viên chuyên nghiệp của
          PGBank để cân nhắc trước khi quyết định tiến hành vay mà không phải lo
          lắng về các khoản phí dịch vụ không rõ ràng.
          <br />
          <br />
          Linh hoạt Chúng tôi hiểu những khó khăn của bạn trong việc thanh toán
          khoản vay. Chúng tôi có thể hỗ trợ bằng cách mở rộng khoản vay hoặc
          thay đổi nếu cần – chỉ cần gọi cho chúng tôi.
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <div className="faq-item">
              <HelpIcon />
              1. Điều kiện được dùng thẻ tiêu dùng là gì
            </div>
          }
        >
          Có quốc tịch Việt Nam và trong độ tuổi 18 - 55 tuổi, có địa chỉ thường
          trú và địa chỉ làm việc hiện tại trong lãnh thổ Việt Nam , có
          CMND/CCCD còn hiệu lực. Có thu nhập ổn định. Có các giấy tờ như bảo
          hiểm xã hội hoặc bảo hiểm y tế, hợp đồng lao động.
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <div className="faq-item">
              <HelpIcon />
              2. Làm sao để được đăng kí
            </div>
          }
        >
          Bạn có thể tải ứng dụng và đăng ký vay ngay trên ứng dụng.
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <div className="faq-item">
              <HelpIcon />
              3. Khi nào thẩm định hồ sơ , công ty có thông báo tiến hành và
              kiểm tra thông tin qua người thân như vợ hoặc chồng , bạn bè của
              người đó không?
            </div>
          }
        >
          Không
          <br />
          <i>
            * Liên hệ người thân chỉ dùng trong trường hợp đến tới kỳ hạn đóng
            tiền người vay không thực hiện nghĩa vụ trả nợ và không thể liên lạc
            được. Lúc đó phía công ty sẽ liên lạc với các liên hệ này để nhắc
            nhở thông báo.
          </i>
        </Collapse.Panel>
      </Collapse>
    </div>
  </div>
));

export default WalletHome;
