import React from "react";
import { observer } from "mobx-react-lite";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { useStores } from "_common/hooks";
import PageLoading from "_common/component/PageLoading";
import WalletFooter from "../Footer/WalletFooter";

const WalletLayout = observer(() => {
  const {
    authStore: { authenticated },
  } = useStores();
  if (!authenticated) return <PageLoading />;
  return (
    <Layout className="sc-pbYBj gDaToN">
      <div id="layout-wrapper" className="sc-pAZqv cuOtHq">
        <div className="sc-qZtCU sc-oUcyK jGwMs">
          <Outlet />
          <WalletFooter />
        </div>
      </div>
    </Layout>
  );
});

export default WalletLayout;
