import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

const FindOut3 = observer(() => {
  const { authStore } = useStores();

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <span className="sc-fzqNqU sc-paXsP kTnDQL">
        Hiện thực hoá ước mơ sở hữu bất cứ món đồ công nghệ mới nhất: iPhone,
        New Macbook… hay những món đồ nội thất sang trọng mà vẫn đảm bảo quản lý
        tài chính cá nhân hiệu quả bằng tính năng trả góp qua thẻ tín dụng
      </span>
      <h4
        className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU"
        style={{ marginBottom: "0.5rem" }}
      >
        Lợi ích của chương trình
      </h4>
      <ul
        className="sc-pjSSY dAvxTh"
        style={{ marginTop: "1rem", marginBottom: "0.5rem" }}
      >
        <li style={{ marginBottom: "1rem" }}>
          0% lãi suất/tháng <br />
          <div style={{ marginTop: "1rem" }}>
            Không phí chuyển đổi đối với những giao dịch trả góp nằm trong hệ
            thống đối tác hợp tác trả góp của TPBank (Tham khảo thông tin đối
            tác chi tiết tại :
            <a
              href="https://tpb.vn/"
              role="button"
              className="sc-AxhUy sc-qQmou dVBRWK"
            >
              &nbsp;http://www.tpb.vn
            </a>
            )
          </div>
        </li>
        <li style={{ marginBottom: "0.5rem" }}>
          Trả góp mọi giao dịch thẻ tại mọi điểm chấp nhận thẻ với phí chuyển
          đổi trả góp linh hoạt:
        </li>
      </ul>
      <ul className="sc-pjSSY dAvxTh" style={{ marginLeft: "1.2rem" }}>
        <li>2.99% áp dụng với kỳ hạn 3, 6 tháng;</li>
        <li>4.99% kỳ hạn 9, 12 tháng.</li>
      </ul>
      <h4
        className="sc-AxmLO sc-fzoLsD sc-oTbqq jcgXTU"
        style={{ marginBottom: "0.5rem" }}
      >
        Cách thức đăng ký
      </h4>
      <ul
        className="sc-pjSSY dAvxTh"
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <li style={{ marginBottom: "1rem" }}>
          Đối với chương trình trả góp 0% lãi suất, không phí áp dụng tại các
          đối tác hợp tác của TPBank: Chủ thẻ đăng ký trả góp giao dịch ngay tại
          thời điểm giao dịch/mua sắm tại điểm chấp nhận thẻ
        </li>
        <li style={{ marginBottom: "1rem" }}>
          Đối với chương trình trả góp 0% lãi suất linh hoạt: Chủ thẻ thực hiện
          yêu cầu trả góp tại ứng dụng
          <a
            href="https://tpb-ebankx.onelink.me/As35/website"
            role="button"
            className="sc-AxhUy sc-qQmou dVBRWK"
          >
            &nbsp;TPBank eBank&nbsp;
          </a>
          hoặc liên hệ đến Tổng đài&nbsp;
          <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
            1900 58 58 85
          </span>
          &nbsp;/&nbsp;
          <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
            1900 6036&nbsp;
          </span>
          để đăng ký chuyển đổi trả góp
        </li>
        <li style={{ marginBottom: "1rem" }}>
          TPBank sẽ gửi Email thông báo về thông tin khoản trả góp được chuyển
          đổi thành công
        </li>
      </ul>
      Mọi chi tiết thêm về thể lệ chương trình, Khách hàng vui lòng tham khảo
      tại : website của TPBank:&nbsp;
      <a
        href="https://tpb.vn/"
        role="button"
        className="sc-AxhUy sc-qQmou dVBRWK"
      >
        http://www.tpb.vn&nbsp;
      </a>
      hoặc liên hệ hotline:&nbsp;
      <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
        1900 58 58 85
      </span>
      &nbsp;/&nbsp;
      <span color="#F85656" className="sc-fzqNqU sc-prOVx dtXwrd">
        1900 6036
      </span>
    </div>
  );
});

export default FindOut3;
