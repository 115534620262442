import React from "react";
import { observer } from "mobx-react-lite";
import { Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";

const Step6 = observer(() => (
  <section id="step-body">
    <div className="wrapper step">
      <div className="content mb-40">
        <div className="max-720 m-auto outner-form">
          <Row
            style={{
              backgroundColor: "white",
              padding: "1rem",
              marginTop: "1rem",
            }}
          >
            <Col span={24}>
              <div className="credit-card-registration-success">
                <CheckOutlined style={{ fontSize: 100 }} />
                <p className="txt_r_l">Chúc mừng</p>
                <p className="txt_r_l">Hồ sơ của quý khách đã nộp thành công</p>
                <p>(Vui lòng liên hệ lại bộ phận duyệt hồ sơ)</p>
              </div>
            </Col>
            <Col span={24}>
              <div style={{ textAlign: "center", marginTop: "3rem" }}>
                <button type="button" id="btn-next" className="btn-cskh">
                  <svg
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    style={{ width: "2em", height: "2em" }}
                  >
                    <path d="M12 1c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h4v1h-7v2h6c1.66 0 3-1.34 3-3V10c0-4.97-4.03-9-9-9z" />
                  </svg>
                  <span>CSKH</span>
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </section>
));

export default Step6;
