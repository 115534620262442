import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";
import { get } from "lodash";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

const CardStatusInfo = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div>
      <WalletHeader title="Khoản thẻ của tôi" isShowBack />
      <div className="header-page-overlay" />
      <div className="card-status-container">
        <div className="card-status-info">
          <div className="item">
            <p>Mã hợp đồng</p>
            <h5>{get(user, "loans[0].ref_no")}</h5>
          </div>
          <div className="item">
            <p>Hạn mức</p>
            <h5>{formatNumber(get(user, "loans[0].amount", 0))} VNĐ</h5>
          </div>
          <div className="item">
            <p>Chi tiêu</p>
            <h5>{formatNumber(get(user, "spent", 0))} VNĐ</h5>
          </div>
        </div>
        <div className="list-status">
          <div className="obj">
            {get(user, "loans[0].status") === "complete" ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <span className="tag">1</span>
            )}
            <p>Đang xem xét</p>
          </div>
          <div className="obj">
            {get(user, "loans[0].status") === "complete" ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <span className="tag">2</span>
            )}
            <p>Vượt qua xác minh</p>
          </div>
          <div className="obj">
            {get(user, "loans[0].status") === "complete" ? (
              <FontAwesomeIcon icon={faCheckCircle} />
            ) : (
              <span className="tag">3</span>
            )}
            <p>
              <b className="text-orange">Được duyệt thành công</b>
              <span className="text-blue">
                Chuyển đổi thành công có thể rút tiền về
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CardStatusInfo;
