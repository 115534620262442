export const DATE_TIME_FORMAT = "DD/MM/YYYY";
export const LOCATION_KEY = "location_id";

export const LocalStorage = {
  Token: "remix_cms_token",
  Device: "remix_device_init",
  StockId: "remix_stock_id",
  LocationId: "remix_location_id",
  Firebase: "remix_firebase_token",
};

const isDev = process.env.NODE_ENV === "development";

export const endpoint = isDev
  ? `${process.env.REACT_APP_API_DEV}/api`
  : `${process.env.REACT_APP_API_PROD}/api`;

export const serverUrl = isDev
  ? process.env.REACT_APP_API_DEV
  : process.env.REACT_APP_API_PROD;

export const socketUrl = isDev
  ? process.env.REACT_APP_SOCKET_CHAT_DEV
  : process.env.REACT_APP_SOCKET_CHAT_PROD;
