import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Form, Button } from "antd";
import { useNavigate } from "react-router";
import UploadCustom from "_common/component/Upload/UploadCustom";

const Step3 = observer(() => {
  const {
    registrationStore: { dataStep3, updateDataStep3 },
    toastStore,
  } = useStores();
  const [front_image, setFrontImage] = useState([]);
  const [back_image, setBackImage] = useState([]);
  const [self_image, setSelfImage] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (dataStep3.front_image) {
      setFrontImage([
        {
          uid: 1,
          name: `front_image`,
          status: "done",
          url: dataStep3.front_image,
        },
      ]);
    }

    if (dataStep3.back_image) {
      setBackImage([
        {
          uid: 1,
          name: `back_image`,
          status: "done",
          url: dataStep3.back_image,
        },
      ]);
    }

    if (dataStep3.self_image) {
      setSelfImage([
        {
          uid: 1,
          name: `self_image`,
          status: "done",
          url: dataStep3.self_image,
        },
      ]);
    }
  }, [dataStep3]);

  const handleReceiveFrontImage = (images) => {
    const currentImage = images
      .filter((i) => i.isNew)
      .map((i) => ({ url: i.url }));

    updateDataStep3({ front_image: currentImage[0]?.url });

    setFrontImage(currentImage);
  };

  const handleReceiveBackImage = (images) => {
    const currentImage = images
      .filter((i) => i.isNew)
      .map((i) => ({ url: i.url }));

    updateDataStep3({ back_image: currentImage[0]?.url });

    setBackImage(currentImage);
  };

  const handleReceiveSelfImage = (images) => {
    const currentImage = images
      .filter((i) => i.isNew)
      .map((i) => ({ url: i.url }));

    updateDataStep3({ self_image: currentImage[0]?.url });

    setSelfImage(currentImage);
  };

  const handleSubmit = () => {
    if (!front_image) {
      return toastStore.error("Tải mặt trước CMND/CCCD");
    }
    if (!back_image) {
      return toastStore.error("Tải mặt sau CMND/CCCD");
    }
    if (!self_image) {
      return toastStore.error("Tải ảnh chân dung lên");
    }

    navigate("/auth/registration/step-4");
    return null;
  };

  return (
    <section id="step-body">
      <div className="wrapper step">
        <div className="content mb-40">
          <div className="max-720 m-auto outner-form">
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              autoComplete="off"
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "1rem",
                marginTop: "1rem",
              }}
            >
              <Form.Item>
                <p className="txt_r_l">Chụp ảnh định danh</p>
                <p>
                  Để tiếp tục quá trình đăng ký, vui lòng chụp ảnh chân dung,
                  cmnd/cccd mặt trước, mặt sau hồ sơ
                </p>
              </Form.Item>
              <Form.Item>
                <UploadCustom
                  maxImages={1}
                  fileList={front_image}
                  onReceiveImages={handleReceiveFrontImage}
                  textDisplay="Mặt trước CMND / CCCD"
                  folder="kyc"
                />
              </Form.Item>
              <Form.Item>
                <UploadCustom
                  maxImages={1}
                  fileList={back_image}
                  onReceiveImages={handleReceiveBackImage}
                  textDisplay="Mặt sau CMND / CCCD"
                  folder="kyc"
                />
              </Form.Item>
              <Form.Item>
                <UploadCustom
                  maxImages={1}
                  fileList={self_image}
                  onReceiveImages={handleReceiveSelfImage}
                  textDisplay="Ảnh chân dung"
                  folder="kyc"
                />
              </Form.Item>
              <Form.Item>
                <p className="txt_r_l">Lưu ý:</p>
                <p>
                  Chụp hình chính diện, mắt nhìn thẳng, không đội mũ nón, đeo
                  kính răm hay khẩu trang
                </p>
              </Form.Item>
              <Form.Item>
                <div className="text-right">
                  <Button
                    id="btn-next"
                    htmlType="submit"
                    className="btn btn_m yellow-btn"
                  >
                    <span>Tiếp tục</span>
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Step3;
