import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";
import { optionBank2 } from "_common/constants/bank_2";

const MeInfoBank = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="me-info-detail-page">
      <WalletHeader title="Tài khoản cá nhân" isShowBack />
      <div className="header-page-overlay" />
      <div className="me-info-detail-container">
        <div className="detail-box">
          <div className="detail-title">Tài khoản cá nhân</div>
          <div className="detail-info">
            <table>
              <tr>
                <td>Tên ngân hàng</td>
                <td>
                  {
                    optionBank2.filter(
                      (bank) => bank.value === user?.bank_name
                    )[0]?.sortName
                  }
                </td>
              </tr>
              <tr>
                <td>Số tài khoản ngân hàng</td>
                <td>{user?.bank_number_display}</td>
              </tr>
              <tr>
                <td>Tên thụ hưởng</td>
                <td>{user?.bank_user_name}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MeInfoBank;
