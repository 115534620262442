import React from "react";
import {
  CreditCardOutlined,
  HomeOutlined,
  HistoryOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function WalletFooter() {
  const handleScrollTop = () => {
    document.getElementById("top-view").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <nav className="footer-page">
      <Link to="/wallet" className="footer-item">
        <HomeOutlined />
        Trang chủ
      </Link>
      <Link to="/wallet/card" className="footer-item">
        <CreditCardOutlined />
        Ví thẻ
      </Link>
      <Link to="/wallet/history" className="footer-item">
        <HistoryOutlined />
        Lịch sử giao dịch
      </Link>
      <Link to="/wallet/me" className="footer-item">
        <UserOutlined />
        Tôi
      </Link>
    </nav>
  );
}
