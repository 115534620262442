import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { optionBank2 } from "_common/constants/bank_2";

const Step4 = observer(() => {
  const {
    registrationStore: { dataStep4, updateDataStep4 },
  } = useStores();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [bankSelectError, setBankSelectError] = useState(false);

  useEffect(() => {
    form.setFieldsValue(dataStep4);
  }, [form, dataStep4]);

  const handleSubmit = (value) => {
    if (!dataStep4.bank_name) setBankSelectError(true);
    else {
      updateDataStep4({ ...dataStep4, ...value });

      navigate("/auth/registration/step-5");
    }
  };

  const bankSelected = useMemo(
    () => optionBank2.filter((bank) => bank.value === dataStep4.bank_name)[0],
    [dataStep4]
  );

  return (
    <section id="step-body">
      <div className="wrapper step">
        <div className="content mb-40">
          <div className="max-720 m-auto outner-form">
            <Form
              form={form}
              layout="vertical"
              initialValues={dataStep4}
              onFinish={handleSubmit}
              autoComplete="off"
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "1rem",
                marginTop: "1rem",
              }}
            >
              <Form.Item>
                <p className="txt_r_l">
                  Thông tin nhận chuyển đổi rút tiền mặt
                </p>
              </Form.Item>
              <div className="bank-select-input">
                <Link to="/auth/registration/bank-select">
                  {dataStep4.bank_name ? (
                    <img src={bankSelected.image} alt={bankSelected.value} />
                  ) : (
                    <>Chọn ngân hàng</>
                  )}
                </Link>
                {bankSelectError && (
                  <div className="bank-select-error">
                    Vui lòng chọn ngân hàng
                  </div>
                )}
              </div>
              <Form.Item
                label="Tên chủ tài khoản"
                name="bank_user_name"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên chủ tài khoản",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Số tài khoản"
                name="bank_account"
                className="variant-standrad"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số tài khoản",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <div className="text-right">
                  <Button
                    id="btn-next"
                    htmlType="submit"
                    className="btn btn_m yellow-btn"
                  >
                    <span>Tiếp tục</span>
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Step4;
