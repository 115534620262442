import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Form, Input, Button } from "antd";
import { AuthApi } from "states/api";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { useStores } from "_common/hooks";

import logo from "../../assets/img/Login/Logo.png";

const Login = observer(() => {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data?.token);
        authStore.updateUser(res?.data?.user);
        navigate("/wallet/card");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleLogin = (value) => {
    mutate(value);
  };

  return (
    <div className="login-page">
      <div className="login-page-overlay" />
      <div className="login-page-container">
        <img src={logo} alt="Logo" className="login-page-logo" />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleLogin}
          autoComplete="off"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form.Item>
            <p
              className="txt_r_l"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Đăng nhập
            </p>
          </Form.Item>
          <Form.Item name="username" style={{ width: "80%" }}>
            <Input placeholder="Nhập số điện thoại" />
          </Form.Item>
          <Form.Item name="password" style={{ width: "80%" }}>
            <Input.Password placeholder="Nhập mật khẩu" />
          </Form.Item>
          <Form.Item>
            <p style={{ color: "white", fontSize: "1rem" }}>
              Chưa có tài khoản?&nbsp;
              <Link to="/auth/registration" style={{ color: "white" }}>
                Đăng ký tài khoản mới
              </Link>
            </p>
          </Form.Item>
          <Form.Item>
            <Button
              id="btn-next"
              htmlType="submit"
              className="login-page-login-btn"
            >
              <span>Đăng nhập</span>
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="login-page-footer" />
    </div>
  );
});

export default Login;
