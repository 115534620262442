import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export const ToastType = {
  NONE: "",
  SUCCESS: "success",
  INFO: "info",
  ERROR: "error",
};

export class ToastStore {
  toast = {
    type: ToastType.NONE,
    text: "",
    autoClose: 2000,
  };

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "ToastStore",
      properties: ["toast"],
    });
  }

  success = (text) => {
    this.toast = {
      type: ToastType.SUCCESS,
      text,
      autoClose: 2000,
    };
  };

  info = (text) => {
    this.toast = {
      type: ToastType.INFO,
      text,
      autoClose: 2000,
    };
  };

  error = (text) => {
    this.toast = {
      type: ToastType.ERROR,
      text,
      autoClose: 2000,
    };
  };

  clear = () => {
    this.toast = {
      type: ToastType.NONE,
      text: "",
      autoClose: 2000,
    };
  };
}
