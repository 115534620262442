import React from "react";
import { observer } from "mobx-react-lite";
import WalletHeader from "_common/component/Layout/Header/WalletHeader";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import { Button } from "antd";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";

const MeInfo = observer(() => {
  const {
    authStore: { authenticated },
  } = useStores();

  return (
    <div className="me-info-page">
      <WalletHeader title="Thông tin tài khoản" isShowBack />
      <div className="header-page-overlay" />
      <div className="me-info-container">
        <nav className="me-nav">
          <Link to="detail" className="me-nav-item">
            <PersonOutlineOutlinedIcon />
            <div className="nav-text">
              Thông tin cá nhân
              <span>Dữ liệu về bản thân và công việc</span>
            </div>
            <span className="nav-badget">Đã hoàn tất</span>
          </Link>
          <Link to="verify" className="me-nav-item">
            <VerifiedUserOutlinedIcon />
            <div className="nav-text">
              Xác thực tài khoản <span>Tăng độ an toàn sử dụng ứng dụng</span>
            </div>
            <span className="nav-badget">Đã hoàn tất</span>
          </Link>
          <Link to="bank" className="me-nav-item">
            <CreditCardOutlinedIcon />
            <div className="nav-text">
              Tài khoản cá nhân
              <span>Tài khoản ngân hàng, vui lòng nhập chính xác</span>
            </div>
            <span className="nav-badget">Đã hoàn tất</span>
          </Link>
          <Link to="relative" className="me-nav-item">
            <GroupsOutlinedIcon style={{ backgroundColor: "orange" }} />
            <div className="nav-text">
              Thông tin người liên hệ
              <span>Dữ liệu các mối liên hệ người thân</span>
            </div>
            <span className="nav-badget">Đã hoàn tất</span>
          </Link>
          <Link to="additional" className="me-nav-item">
            <NoteAddOutlinedIcon style={{ backgroundColor: "orange" }} />
            <div className="nav-text">
              Dữ liệu bổ sung <span>Tăng cơ hội chấp thuận hồ sơ của bạn</span>
            </div>
            <span className="nav-badget">Đã hoàn tất</span>
          </Link>
          <Link to="signature" className="me-nav-item">
            <NoteAddOutlinedIcon style={{ backgroundColor: "orange" }} />
            <div className="nav-text">
              Chữ ký <span>Chữ ký hợp đồng</span>
            </div>
            <span className="nav-badget">Đã hoàn tất</span>
          </Link>
        </nav>
        <div style={{ padding: "0 1rem" }}>
          Tôi cam đoan những thông tin trên là chính xác và chịu hoàn toàn mọi
          trách nhiệm nếu có bất cứ sai sót nào về nội dung thông tin đã điền
        </div>
        {!authenticated && (
          <div className="me-register">
            <Button>Đăng ký</Button>
          </div>
        )}
      </div>
    </div>
  );
});

export default MeInfo;
